// import React, { useState } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Modal, Typography } from '@mui/material';
// import { Colors } from '../../../../constants/constants';

// // Sample data for the reports
// const sampleRows = [
//   { id: 1, reportName: 'Opsti', isChecked: false },
//   { id: 2, reportName: 'KV', isChecked: false },
//   { id: 3, reportName: 'Probava', isChecked: true },
// ];

// // Define the interface for the report table rows
// export interface IReportTable {
//   id: number;
//   reportName: string;
//   isChecked: boolean;
// }

// // Props interface for ReportTable component
// interface ReportTableProps {
//   data: IReportTable[];
// }

// const ReportTable: React.FC<ReportTableProps> = ({ data }) => {
//   const [open, setOpen] = useState(false);
//   const [selectedReport, setSelectedReport] = useState<string | null>(null);

//   const handleOpen = (reportName: string) => {
//     setSelectedReport(reportName);
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setSelectedReport(null);
//   };

//   return (
//     <div style={{ height: '100%', width: '100%' }}>
//       <TableContainer style={{ height: '100%', overflow: 'auto' }}>
//         <Table size="small" stickyHeader>
//           <TableBody>
//             {data.map((row) => (
//               <TableRow key={row.id} sx={{ backgroundColor: row.isChecked ? Colors.SuccessColor : 'white' }}>
//                 <TableCell>{row.reportName}</TableCell>
//                 <TableCell>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     disabled={!row.isChecked}
//                     onClick={() => handleOpen(row.reportName)}
//                     sx={{
//                       backgroundColor: row.isChecked ? 'lightgreen' : 'lightred',
//                       '&:hover': {
//                         backgroundColor: row.isChecked ? 'lightgreen' : 'lightred',
//                       },
//                     }}
//                   >
//                     Pristupi pregledu
//                   </Button>
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Modal Component */}
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Paper style={{ padding: '20px', maxWidth: '400px', margin: 'auto', marginTop: '100px' }}>
//           <Typography id="modal-title" variant="h6" component="h2">
//             Pregled: {selectedReport}
//           </Typography>
//           <Typography id="modal-description" sx={{ mt: 2 }}>
//             Ovde možete pregledati detalje vezane za {selectedReport}.
//           </Typography>
//           <Button variant="contained" onClick={handleClose} sx={{ mt: 2, backgroundColor: 'lightgreen' }}>
//             Zatvori
//           </Button>
//         </Paper>
//       </Modal>
//     </div>
//   );
// };

// // Export default component with sample data
// export const ReportTableWithSampleData: React.FC = () => {
//   return <ReportTable data={sampleRows} />;
// };

// export default ReportTable;

import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Modal, Typography } from '@mui/material';
import { Colors } from '../../../constants/constants';

// Sample data for the reports
const sampleRows = [
    { id: 1, reportName: 'Opsti', isChecked: false },
    { id: 2, reportName: 'KV', isChecked: false },
    { id: 3, reportName: 'Probava', isChecked: true },
];

// Define the interface for the report table rows
export interface IReportTable {
    id: number;
    reportName: string;
    isChecked: boolean;
}

// Props interface for ReportTable component
interface ReportTableProps {
    data: IReportTable[];
}

// Mapping reportName to tests and categories
const reportDetails = {
    Opsti: {
        tests: "Kompletna krvna slika, Analiza urina",
        categories: "Svi korisnici"
    },
    KV: {
        tests: "Masnoce u krvi, EKG, Ultrazvuk srca, Test opterecenja",
        categories: "Muskarci 40 do 49, Muskarci 50+, Zene 50+, Rizik od KVS"
    },
    Probava: {
        tests: "Test krv u stolici, Okultno krvarenje, Kolonoskopija, KKS sa sedimentacijom",
        categories: "Muskarci 50+, Zene 50+"
    }
};

const ReportTable: React.FC<ReportTableProps> = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [selectedReport, setSelectedReport] = useState<string | null>(null);

    const handleOpen = (reportName: string) => {
        setSelectedReport(reportName);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedReport(null);
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <TableContainer style={{ height: '100%', overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id} sx={{ backgroundColor: 'white' }}>
                                <TableCell sx={{fontWeight: 'bold', border: 'none'}}>{row.reportName}</TableCell>
                                <TableCell sx={{ border: 'none' }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!row.isChecked}
                                        onClick={() => handleOpen(row.reportName)}
                                        sx={{
                                            backgroundColor: row.isChecked ? 'lightgreen' : 'lightred',
                                            '&:hover': {
                                                backgroundColor: row.isChecked ? 'lightgreen' : 'lightred',
                                            },
                                        }}
                                    >
                                        Pristupi pregledu
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal Component */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Paper style={{ padding: '20px', maxWidth: '400px', margin: 'auto', marginTop: '100px' }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        Pregled: {selectedReport}
                    </Typography>
                    {selectedReport && selectedReport in reportDetails && (
                        <>
                            <Typography id="modal-description" sx={{ mt: 2 }}>
                                <strong>Testovi:</strong> {reportDetails[selectedReport as keyof typeof reportDetails].tests}
                            </Typography>
                            {/* <Typography id="modal-categories" sx={{ mt: 2 }}>
                                <strong>Kategorije:</strong> {reportDetails[selectedReport as keyof typeof reportDetails].categories}
                            </Typography> */}
                        </>
                    )}
                    <Button variant="contained" onClick={handleClose} sx={{ mt: 2, backgroundColor: 'lightgreen' }}>
                        Zatvori
                    </Button>
                </Paper>
            </Modal>
        </div>
    );
};

// Export default component with sample data
export const ReportTableWithSampleData: React.FC = () => {
    return <ReportTable data={sampleRows} />;
};

export default ReportTable;
