import Textarea from '@mui/joy/Textarea';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { useEffect, useState } from "react";
import { IQuestion } from "../survey-questions";
import surveySlice from "../../../../store/survey/survey-slice";
import { IHealthAssesment } from "../../survey-start/survey-start";
import { SubmitAnswersInDatabase } from "../../../../store/survey/survey-actions";
import { Form, Row, Col } from 'react-bootstrap';
import '../answers/answer.css'
import { Box, FormControlLabel, Grid, Slider, Tab, Tabs } from '@mui/material';
import { Colors } from '../../../../constants/constants';
 
export interface IAnswerSelectionType {
    answerId: number,
    isSelected: boolean,
    text?: string
}
 
export interface ISubmitAnswersModel {
    questionId: number,
    healthAssesmentId: number,
    answers: IAnswerSelectionType[]
    // additionalComment?: string
}
 
export interface IAnswer {
    id: number,
    questionId: number,
    orderNo: number,
    text: string,
    isActive: boolean,
    isSelected: boolean,
    isSavedInDatabase: boolean,
    isAnswered: boolean,
    additionalComment?: string,
    tabIndex: number
}
 
export interface IAnswerProps {
    question: IQuestion,
    inputType: string | 'default'
}
 
export const Answer: React.FunctionComponent<IAnswerProps> = (props) => {
    const { question, inputType } = props;
    const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers);
    const healthAssesment: IHealthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment);
    const dispatch: any = useDispatch();
 
    const [filteredAnswers, setFilteredAnswers] = useState<IAnswer[]>(answers.filter(item => item.questionId === question.id && item.text !== 'input'));
    const [filteredAnswers1, setFilteredAnswers1] = useState<IAnswer[]>(answers.filter(item => item.questionId === question.id && item.text === 'input'));
    // const tabIndex: number = useSelector((state: RootState)=> state.survey.Answers.find(x => x.id == filteredAnswers1[0]?.id)?.tabIndex || 0)
    const [selectedAnswersIds, setSelectedAnswersIds] = useState<Set<number>>(new Set(filteredAnswers.filter(x => x.isSelected).map(x => x.id)));
    // const [tabIndex, setTabIndex] = useState<number>(filteredAnswers1[0]?.tabIndex);  
// console.log(filteredAnswers)
    const [additionalComment, setComment] = useState<string>('')
   
    // const handleTabChange = (
    //     // event: React.SyntheticEvent,
    //     newValue: number
    // ) => {
    //     // setTabIndex(newValue);
    // };
   
    useEffect(() => {
        if (filteredAnswers1[0] && additionalComment !== '') {
           
            const timer = setTimeout(() => {
                // dispatch(surveySlice.actions.setAnswerSimple({...filteredAnswers1[0], additionalComment: additionalComment, tabIndex: tabIndex}));
                dispatch(surveySlice.actions.setAnswerSimple({...filteredAnswers1[0], additionalComment: additionalComment}));
                dispatch(surveySlice.actions.setQuestion({ questionId: filteredAnswers1[0]?.questionId, isSavedInDB: false }));
                setFilteredAnswers1(answers.filter(item => item.questionId === question.id && item.text === 'input'));
               
            }, 2000);
         
            return () => {
                clearTimeout(timer);
            };
        }
       
    }, [additionalComment]);
 
    // console.log("tabIndex", filteredAnswers1.find(x => x.id == 615))
    useEffect(() => {
       
        // if(filteredAnswers.findIndex(x => x.text == 'input') === -1) setTabIndex(0);
        setFilteredAnswers(answers.filter(item => item.questionId === question.id && item.text !== 'input'));
        setFilteredAnswers1(answers.filter(item => item.questionId === question.id && item.text === 'input'));
    }, [answers, question.id, filteredAnswers1[0]]);
 
 
//    console.log('RERENDER ANSWER')
const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const answerId = Number(event.target.value);

    setSelectedAnswersIds(prevSelected => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(answerId)) {
            newSelected.delete(answerId);
        } else if (newSelected.size < question.maxSelect) {
            newSelected.add(answerId);
        } else if (question.maxSelect === 1) {
            newSelected.clear();
            newSelected.add(answerId);
        }

        return newSelected;
    });
};
 
    const marks = filteredAnswers.map(x => ({
        value: x.id,
        label: x.text
    }));
 
    let answerSection: JSX.Element | null = null;
 
    // console.log('additionalComment', additionalComment)
    if (inputType === 'input') {
        answerSection = (
            <div className="bg-white p-0 rounded  w-100">
                <Row sx={{ width: '100%  !important', margin: 0 }}>
                    <Col xs={12} sm={12} md={12} sx={{ padding: 0, width: '100% !important' }}>
                        <Textarea
                            sx={{ width: '100% !important', backgroundColor: 'white' }}
                            color="neutral"
                            minRows={3}
                            size="lg"
                            value={additionalComment !== '' ? additionalComment : filteredAnswers1[0]?.additionalComment}
                            variant="plain"
                            placeholder="Odgovor..."
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setComment(event.target.value)
                                // dispatch(surveySlice.actions.setAnswerSimple({...filteredAnswers1[0], additionalComment: event.target.value, tabIndex: tabIndex}));
                                // dispatch(surveySlice.actions.setQuestion({ questionId: filteredAnswers1[0].questionId, isSavedInDB: false }));
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
     else if (inputType === 'slider') {
        answerSection = (
            <div className="bg-white p-0 rounded w-100">
                <Row sx={{ width: '100% !important', margin: 0, padding: 0 }}>
                    <Col xs={12} sm={12} md={12} sx={{ padding: 0, width: '100% !important' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100px', mx: 1 }}>
                            <Slider
                                onChange={(event: Event, value: number | number[]) => {
                                    const answer = filteredAnswers.find(x => x.id == value);
                                    if (answer) {
                                        dispatch(surveySlice.actions.setAnswerSlider(answer));
                                        dispatch(surveySlice.actions.setQuestion({ questionId: answer.questionId, isSavedInDB: false }));
                                    }
                                }}
                                aria-label="Slider"
                                defaultValue={filteredAnswers.find(x => x.isSelected)?.id || 0}
                                step={1}
                                marks={marks}
                                valueLabelDisplay="off"
                                sx={{ width: '90%', color: 'grey' }}
                                min={marks[0]?.value}
                                max={marks[marks.length - 1]?.value}
                            />
                        </Box>
                    </Col>
                </Row>
            </div>
        );
    } else {
        // Default: Render checkboxes
        const twoColumns = filteredAnswers.length >= 6;
        answerSection = (
            <div className="bg-white ps-3 rounded py-2  w-100">
                <Grid container spacing={2}>
                    {filteredAnswers.map((answer: IAnswer) => (
                        <Grid item xs={12} sm={twoColumns ? 6 : 12} key={answer.id}>
                            <FormControlLabel
                                sx={{ display: 'flex', justifyContent: 'start' }}
                                control={
                                    <Box sx={{ mx: 1 }}>
                                        <Form.Check
                                            checked={selectedAnswersIds.has(answer.id)}
                                            onChange={(e) => {
                                                handleCheckboxChange(e);
                                                dispatch(surveySlice.actions.setAnswer(answer));
                                                dispatch(surveySlice.actions.setQuestion({ questionId: answer.questionId, isSavedInDB: false }));
                                            }}
                                            value={answer.id}
                                        />
                                    </Box>
                                }
                                label={answer.text}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }
 
    return (
        <Box sx={{ width: '100%', bgcolor: 'white', border: '1px dotted lightgrey', borderRadius: '5px' }}>
            <Tabs
            variant="fullWidth"
            value={filteredAnswers1[0]?.tabIndex || 0}
                onChange={(_,newvalue) =>  dispatch(surveySlice.actions.setAnswerSimple({...filteredAnswers1[0], tabIndex: newvalue}))}
                aria-label="answer tabs"
                sx={{
                    mb: 0,
                    minHeight: 12,
                    // backgroundColor: 'black',
                    // color: 'white',
                    '& .MuiTab-root': {
                        padding: '4px 8px',
                        minHeight: 12,
                        fontSize: '0.6rem' ,
                        color: 'black'
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: 'grey',
                        height: '2px'
                    },
                    display: (inputType === 'input' || filteredAnswers1.findIndex(x => x.text === 'input') === -1) ? 'none' : ''
                }}
            >
                <Tab label="Odgovor" disabled={inputType === 'input'}/>
                <Tab label="Komentar" />
            </Tabs>
 
            {(filteredAnswers1[0]?.tabIndex || 0) === 0 && answerSection }
            {filteredAnswers1[0]?.tabIndex === 1 && (
                <Textarea
                    sx={{ width: '100% !important', backgroundColor: 'white' }}
                    color="neutral"
                    minRows={3}
                    size="lg"
                    variant="plain"
                    placeholder="Vas odgovor..."
                    value={additionalComment !== '' ? additionalComment : filteredAnswers1[0]?.additionalComment}
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setComment(event.target.value)
                        // dispatch(surveySlice.actions.setAnswerSimple({...filteredAnswers1[0], additionalComment: event.target.value, tabIndex: tabIndex}));
                        // dispatch(surveySlice.actions.setQuestion({ questionId: filteredAnswers1[0].questionId, isSavedInDB: false }));
                    }}
                   
                />
            )}
        </Box>
    );
};
 
 