
import { AccessTime } from "@mui/icons-material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Genders } from "../../constants/constants";
import { IUser } from "../../components/survey/survey-form/survey-form";
import { IFamilyHistory } from "./login-modal-form-actions";

export interface IModalShow {
    show: boolean
}

export interface IModal {
    IsAuthenticated: boolean
    userData: IUser
}

const initialState: IModal = {
    IsAuthenticated: true,
    userData: {
        // id: 0, firstName: '', lastName: '', email: ''
        cardiovascular: false,
        diabetes: false,
        cancer: false,
        highBloodPressure: false,
        other: false,
        mentalIllness: false,
    }
}


const modalFormSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<IModal>): void => {
            state.IsAuthenticated = action.payload.IsAuthenticated;
        },
        setUserData: (state, action: PayloadAction<IModal>): void => {
            state.userData = action.payload.userData;
            state.IsAuthenticated = action.payload.IsAuthenticated;
        },
        setFamilyHistory: (state, action: PayloadAction<IFamilyHistory>): void => {
            state.userData = {
                ...state.userData,
                cardiovascular: action.payload.cardiovascular || false,
                diabetes: action.payload.diabetes  || false,
                cancer: action.payload.cancer  || false,
                cancerType: action.payload.cancerType  || '',
                highBloodPressure: action.payload.highBloodPressure  || false,
                other: action.payload.other  || false,
                otherConditions: action.payload.otherConditions  || '',
                mentalIllness: action.payload.mentalIllnessInFamily || false
            };

        }
    }
});


export const { setUserData, setFamilyHistory } = modalFormSlice.actions;
export default modalFormSlice;