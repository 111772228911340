import { Box, Typography, Button, Grid, Snackbar, Alert } from "@mui/material";
import { Colors } from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store/store";
import { CreateaHealthAssesment, GetHealthAssesment } from "../../../store/survey/survey-actions";
import surveySlice from "../../../store/survey/survey-slice";
import { IUser } from "../survey-form/survey-form";

export interface ISurveyStartProps {
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export interface IHealthAssesment {
    id: number | null,
    userId: number | null,
    startedOn: Date | null,
    completedOn: Date | null
}

export const SurveyStart: React.FunctionComponent<ISurveyStartProps> = (props) => {
    const { setCurrentPage } = props;
    const dispatch: any = useDispatch();
    const userData: IUser = useSelector((state: RootState) => state.loginModal.userData)
    const open = useSelector((state: RootState) => state.survey.SnackbarStartPage)

    // console.log('snackbar open', open)

    const handleStartNewAssesment = () => {
        if (userData.id != undefined) {
            // dispatch(surveySlice.actions.setAnswers([]))
            dispatch(CreateaHealthAssesment(fetch, userData.id))
            dispatch(surveySlice.actions.setSnackbarStartPage(false))
        }
        else
            console.error("User data is corrupted or missing completely");
    }

    const handleContinueWithLatestHealthAssesment = () => {
        if (userData.id != undefined)
        {
            dispatch(GetHealthAssesment(fetch, userData.id))
            dispatch(surveySlice.actions.setSnackbarStartPage(false))
        }    
        else
            console.error("User data is corrupted or missing completely");
    }

     // Function to handle Snackbar close
     const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(surveySlice.actions.setSnackbarStartPage(false))
        // setOpen(false);
    };


    return (

            <Box
                sx={{
                    minHeight: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    px: 2,
                }}
            >
                <Typography
                    variant="h5"
                    component="p"
                    sx={{ pb: 2 }}
                >
                    Dobrodošli u našu anketu!
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    sx={{ pb: 4, maxWidth: '600px' }}
                >
                    Vaše informacije će nam pomoći da bolje razumemo vaše zdravstvene potrebe i usmerimo vas na odgovarajuće preglede. Anketa će vam oduzeti oko 10 minuta.
                </Typography>
                {/* <Typography
                variant="h4"
                component="h2"
                sx={{ pb: 2, mb: 0, borderColor: 'divider' }}
            >
                Upitnik za sistematski pregled
            </Typography> */}


                <Grid container spacing={0} xs={12} sx={{ mt: 3, width: '80%', justifyContent: 'center' }}>
                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        <Button sx={{
                            backgroundColor: Colors.MMYellow1, color: 'black', border: '1px solid lightgrey', mx: 5
                            , '&:hover': {
                                backgroundColor: 'black',
                                borderColor: 'gray',
                                color: 'white',
                            },
                        }} type="submit" variant="contained" fullWidth onClick={() => { setCurrentPage(1); handleStartNewAssesment() }}>
                            ZAPOCNI NOVU
                        </Button>
                    </Grid>
                    <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 2, width: '100%' }}>
                        <Button sx={{
                            backgroundColor: Colors.MMYellow1, color: 'black', border: '1px solid lightgrey', mx: 5
                            , '&:hover': {
                                backgroundColor: 'black',
                                borderColor: 'gray',
                                color: 'white',
                            },
                        }} type="submit" variant="contained" fullWidth onClick={() => { setCurrentPage(1); handleContinueWithLatestHealthAssesment() }}>
                            NASTAVI
                        </Button>
                    </Grid>
                </Grid>

                  {/* Snackbar in Top Center */}
                  <Snackbar
                        open={open}
                        // autoHideDuration={10000}
                        onClose={handleClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Positioning at top center
                    >
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            variant="filled"
                            sx={{
                                width: '100%',
                                maxWidth: '400px', 
                                whiteSpace: 'pre-wrap', 
                                wordWrap: 'break-word', 
                                // border: '1px dotted lightgreen'
                            }}
                        >
                            Čestitamo! Uspešno ste popunili naš zdravstveni upitnik. Vaši odgovori se obrađuju. Nakon završene obrade, na našem portalu dobićete preporuku za Sistematski Pregled, personalizovanu za Vas. O završetku obrade obavestićemo Vas putem mail adrese koju ste uneli prilikom registracije.
                        </Alert>
                    </Snackbar>

            </Box>

    );
}
