import React, { useEffect, useState } from 'react';
import { Box, Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { IUser } from '../../components/survey/survey-form/survey-form';
import { Colors } from '../../constants/constants';
import BarChartComponent from '../../components/medical-checkup/barchart/medical-checkup-barchart';
import ReportDataGrid, { IReportTable } from '../../components/medical-checkup/report/report';
import ReportTable from '../../components/medical-checkup/report/report';
import { IAnswer } from '../../components/survey/survey-questions/answers/answer';

export interface IMedicalCheckupProp {
    checkupType: 'Opsti' | 'KV' | 'Probava';
}

export const MedicalCheckupPage: React.FunctionComponent<IMedicalCheckupProp> = ({ checkupType }) => {
    const userData: IUser = useSelector((state: RootState) => state.loginModal.userData);

    const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers)

    const [reportTable, setReportTable] = useState<IReportTable[]>([
        { id: 1, reportName: 'Opsti', isChecked: false },
        { id: 2, reportName: 'KV', isChecked: false },
        { id: 3, reportName: 'Probava', isChecked: false },
    ]);



    const reportData = () => {
        const questionsForKV = [6, 15, 16, 17, 18, 19, 20, 21]; // Questions for KV checkup 1.	Pitanja 6,8,9,10,1,12,13,14 
        const questionsForProbava = [32, 104, 105, 34, 51, 100, 52, 54]; // Questions for Probava checkup 23,24,25,34 b,d i e

        // Initialize the report object with default values
        const reportObject: IReportTable[] = [
            { id: 1, reportName: 'Opsti', isChecked: false },
            { id: 2, reportName: 'KV', isChecked: false },
            { id: 3, reportName: 'Probava', isChecked: false },
        ];

        // Check if user answered positively to any of the KV questions
        const answeredKV = questionsForKV.some(questionId =>
            answers.some(answer => answer.questionId === questionId && answer.isSelected) // Assuming isSelected indicates a positive response
        );

        // Check if user answered positively to any of the Probava questions and not to any KV questions
        const answeredProbava = questionsForProbava.some(questionId =>
            answers.some(answer => answer.questionId === questionId && answer.isSelected)
        );

        // Determine the checkup type based on the user's answers
        if (answeredKV) {
            reportObject[1].isChecked = true; // Set KV checkup
        } else if (answeredProbava) {
            reportObject[2].isChecked = true; // Set Probava checkup
        } else {
            reportObject[0].isChecked = true; // Default to Opsti checkup
        }
        // console.log(reportObject)
        return reportObject;
    };

    useEffect(() => { setReportTable(reportData()) }, [answers])

    const getCheckupDetails = () => {
        switch (checkupType) {
            case 'KV':
                return {
                    title: 'Kardiovaskularni (KV) sistematski pregled',
                    description:
                        'Preporučujemo vam da se podvrgnete detaljnom kardiovaskularnom pregledu. Ovaj pregled obuhvata testove za procenu stanja vašeg srca i krvnih sudova.',
                };
            case 'Probava':
                return {
                    title: 'Pregled probavnog sistema',
                    description:
                        'Preporučujemo vam da obavite pregled probavnog sistema kako bismo procenili njegovo stanje i sprečili eventualne probleme.',
                };
            case 'Opsti':
            default:
                return {
                    title: 'Opšti sistematski pregled',
                    description:
                        'Preporučujemo vam opšti sistematski pregled kako biste dobili kompletnu sliku o vašem zdravstvenom stanju.',
                };
        }
    };



    return (
        <Box p={3} display="flex" flexDirection="column" alignItems="center" sx={{ width: '100%' }}>
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '100%', textAlign: 'center', backgroundColor: Colors.MMYellow2lighter }}>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12} sm={12}>
                        <Paper sx={{ height: '100%', weight: '100%', p: 1 }}><Typography variant='h4'>PRIMER IZVESTAJA</Typography></Paper>
                    </Grid>
                </Grid >
                <Grid container spacing={2}>
                    {/* User data section in a table */}
                    <Grid item xs={12} sm={6}>
                        <Paper sx={{ p: 1, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>Korisnicke informacije</Typography>
                            <TableContainer>

                                <Table size="small" sx={{ borderCollapse: 'collapse' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Ime</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{userData.firstName} {userData.lastName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Email</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{userData.email}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Telefon</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{userData.phoneNumber}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Pol</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{userData.gender}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', border: 'none' }}>Datum Rodjenja</TableCell>
                                            <TableCell sx={{ border: 'none' }}>{userData.dateOfBirth}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </TableContainer>
                        </Paper>
                    </Grid>

                    {/* Checkup description section */}
                    <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="center">
                        <Paper sx={{ p: 1, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Sistematski pregledi
                            </Typography>
                            {/* <Typography variant="body1" gutterBottom>{description}</Typography> */}
                            <ReportTable data={reportTable} />
                        </Paper>
                    </Grid>

                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} display="flex" flexDirection="column" justifyContent="center">

                        <Paper sx={{ p: 1, height: '100%', mt: 2 }}>
                            <Typography>MEDICINSKA ISTORIJA PACIJENTA</Typography>
                            <BarChartComponent />
                        </Paper>
                    </Grid>
                </Grid>


            </Paper>
        </Box>
    );
};
