import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Answer } from './answers/answer';
import { Card, CardContent, Typography, Grid, useTheme, Box } from '@mui/material';
import { Colors, Sizes } from '../../../constants/constants';
import { Height } from '@mui/icons-material';
import Alert from '@mui/material/Alert';

export interface INewSurveyProps {
    questionsFiltered: IQuestion[] | any[],
    completedGroup: boolean
}

export interface IQuestion {
    id: number | undefined,
    text: string | undefined,
    additionalComment?: string | undefined,
    questionGroupId: number | undefined,
    image?: string | undefined,
    orderNo: number,
    // answers?: IUserAnswer[] | undefined,
    userAnswered?: number[] | undefined,
    isMultipleSelect: boolean,
    maxSelect: number,
    minSelect: number,
    parentId: number,
    inputType: string,
    isSavedInDB: boolean,
    error?: string,
    isAnswered: boolean
}

export const SurveyQuestions: React.FunctionComponent<INewSurveyProps> = ({ questionsFiltered, completedGroup }) => {
    const browserWidth = useSelector((state: RootState) => state.general.browserWidth) || window.innerWidth;
    const theme = useTheme(); // For theme-based styling if needed


    return (
        <Card sx={{ backgroundColor: completedGroup ? Colors.SuccessColor : Colors.MMYellow2lighter, padding: 0, border: 'none', boxShadow: 'none', zIndex: 1000 }}>
            {questionsFiltered
                .map((question: IQuestion, i: number) => {
                    if (question.id === undefined) return null;
                    const flexDirection = browserWidth > Sizes.BreakPoint ? 'row' : 'column'
                    const justContent = browserWidth > Sizes.BreakPoint ? 'end' : 'start'
                    const textAlign = browserWidth > Sizes.BreakPoint ? 'start' : 'center'
                    // const regex = /^[a-zA-Z]\)\s/;

                    // const isSubquestion: boolean = regex.test(question.text || "")
                    const isSubquestion: boolean = question.parentId != 0

                    const specialQuestion = questionsFiltered.findIndex((q: IQuestion) => q.parentId == question.id) !== -1

                    const cssStyle = !isSubquestion ? {
                        backgroundColor: completedGroup ? Colors.SuccessColor : Colors.MMYellow2lighter, fontWeight: 'bold', width: '100%',
                        height: '100%'
                    } :
                        {
                            backgroundColor: completedGroup ? Colors.SuccessColor : Colors.MMYellow2lighter,
                            display: 'flex',
                            width: '75%'
                        }

                    return (
                        <Card key={question.id} sx={
                            {
                                marginBottom: 1,
                                borderRadius: 0,
                                // borderTop: !isSubquestion ? '1px dotted lightgrey' : '', 
                                boxShadow: 'none', backgroundColor: completedGroup ? Colors.SuccessColor : Colors.MMYellow2lighter
                            }}>
                            <CardContent sx={{ display: 'flex', border: 'none', flexDirection: flexDirection, alignItems: 'center' }}>
                                <Grid container spacing={5} alignItems="center" alignContent={"center"}>
                                    <Grid item xs={12} md={6} >
                                        {/* <Typography align="center" sx={{backgroundColor: completedGroup ? Colors.SuccessColor : 'green'}}> */}
                                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column' }} textAlign={textAlign}>
                                            <Box sx={cssStyle}>
                                                {/* {question.id}. */}
                                                {/* {question.orderNo}  */}
                                                {question.text}
                                            </Box>
                                            {question.error != undefined && <Alert severity="error" sx={{ width: '100%' }}>{question.error}</Alert>}
                                        </Box>
                                        {/* </Typography> */}
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: justContent, alignItems: 'center' }}>
                                        {!specialQuestion && <Answer question={question} inputType={question.inputType} />}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    );
                })}
        </Card>
    );
};
