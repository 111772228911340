import { Routes, Route, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { IQuestionGroup, QuestionGroups } from "../../components/survey/survey-question-groups";
import { useDispatch, UseSelector, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect, useMemo, useState } from 'react';
import { fetchAnswers, fetchQuestionGroups, fetchQuestions, SubmitAnswersInDatabase } from '../../store/survey/survey-actions';
import { SurveyForm } from '../../components/survey/survey-form/survey-form';
import { Colors, Sizes } from '../../constants/constants';
import { IAnswer, ISubmitAnswersModel } from '../../components/survey/survey-questions/answers/answer';
import { Box, Button, dividerClasses, MobileStepper, Stack, Typography } from '@mui/material';
import { SurveyStart } from '../../components/survey/survey-start/survey-start';
import { SurveyEnd } from '../../components/survey/survey-end/survey-end';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import surveySlice from '../../store/survey/survey-slice';
import { GetFamilyHistory } from '../../store/login-modal-form/login-modal-form-actions';
export interface ISurveyPage {

}

export const SurveyPage: React.FunctionComponent<ISurveyPage> = props => {
    const dispatch: any = useDispatch();
    const questionGroups: IQuestionGroup[] = useSelector((state: RootState) => state.survey.QuestionGroups)
    const questions = useSelector((state: RootState) => state.survey.Questions)
    const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers);
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const browserWidth = useSelector((state: RootState) => state.general.browserWidth)
    const userData = useSelector((state: RootState) => state.loginModal)
    const healthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment)
    const theme = useTheme();

    const pageNames = ['', 'OPSTI PODACI'];

    const additionalNames = questionGroups
        .filter(x => x.inLevel === 1)
        .map(x => x.name)
        .filter((name): name is string => name !== undefined);

    const finalPageNames = [...pageNames, ...additionalNames, 'KRAJ', 'IZBOR SISTEMATKOG PREGLEDA'];

    const open = useSelector((state: RootState) => state.survey.SnackbarStartPage)


    useEffect(() => { setCurrentPage(0); }, [open])


    // Navigate to the next page
    // console.log(userData.userData)
    // console.log(healthAssesment)
    const nextPage = () => {
        setCurrentPage(currentPage + 1)
    };
    // console.log(answers)
    // Navigate to the previous page
    const prevPage = () => {
        setCurrentPage(currentPage - 1)
    };

    useEffect(() => {
        if (currentPage == 0) {
            navigate(`/`)
        }
        else {
            navigate(`/${currentPage}`)
        }

    }, [currentPage]);

    useEffect(() => {
        dispatch(fetchQuestionGroups(fetch));
        dispatch(fetchQuestions(fetch));
        if (healthAssesment.id != null) {
            // console.log('PRETEBANI ANSWERI')
            dispatch(fetchAnswers(fetch, userData.userData.id, healthAssesment.id));
        }

    }, [healthAssesment])
    useEffect(() => {
        handleDataBaseUpdates()
    }, [answers])

    const handleDataBaseUpdates = (): boolean => {
        const notSavedInDB = questions.filter(x => !x.isSavedInDB);
        notSavedInDB.forEach(question => {
            const databasePackage: ISubmitAnswersModel = {
                questionId: question.id!,
                healthAssesmentId: healthAssesment.id!,
                answers: answers
                    .filter(x => x.questionId === question.id && (x.isSelected || x?.additionalComment))
                    .map(answer => ({
                        answerId: answer.id,
                        isSelected: answer.isSelected,
                        text: answer?.additionalComment || ""
                    }))
                // additionalComment: answers.find(x => x.questionId === question.id && x.text == 'input')?.additionalComment

            };
            // console.log('databasePackage', databasePackage)
            dispatch(SubmitAnswersInDatabase(fetch, databasePackage));
        });

        return true;
    };

    useEffect(() => {
        // console.log("userchange", userData.userData)
        if (userData.userData.id != undefined)
            dispatch(GetFamilyHistory(fetch, userData.userData.id))
    }, [userData.userData.id])



    // console.log('questionGroups', questionGroups)
    let boxWidth = {}
    if (browserWidth != undefined && browserWidth > Sizes.BreakPoint) boxWidth = { width: '75%' }
    if (browserWidth != undefined && browserWidth <= Sizes.BreakPoint) boxWidth = { width: '100%' }

    const lastPage = 2 + questionGroups.filter(x => x.inLevel === 1).length;
    const isSmallScreen = window.innerWidth <= 997;

    const [orderedCategories, setOrderedCategories] = useState<IQuestionGroup[]>([]);

    useEffect(() => {
        // Sortiraj kopiju `questionGroups` po `orderno`
        const sortedGroups = [...questionGroups]
            .filter(x => x.inLevel === 1)
            .sort((a, b) => {
                const orderA = a.orderno ?? 1000;
                const orderB = b.orderno ?? 1000;
                return orderA - orderB;  // Rastući redosled
            });
    
        // console.log("Sorted groups:", sortedGroups);
        setOrderedCategories(sortedGroups);
    }, [questionGroups]);
  

    return (
        <>
            {(currentPage > 0 && isSmallScreen && !open) &&
                <Box sx={{
                    display: 'flex', justifyContent: 'center', position: 'fixed', zIndex: 1000, width: '100%',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: Colors.MMYellow,
                    borderTop: '1px solid lightgrey'
                }}>
                    <MobileStepper
                        variant="progress"
                        steps={lastPage + 1}
                        position="static"
                        activeStep={currentPage}
                        sx={{
                            bgcolor: Colors.MMYellow,
                            maxWidth: 400, flexGrow: 1,
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: 'black',
                            },
                            '& .MuiLinearProgress-root': {
                                backgroundColor: 'lightgrey',  // Change unfinished progress color to grey
                            },
                        }}
                        nextButton={
                            <Button size="small" onClick={nextPage} disabled={currentPage === (lastPage)}
                                sx={{ color: 'black' }}>
                                Next
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={prevPage} disabled={currentPage === 0}
                                sx={{ color: 'black' }}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Back
                            </Button>
                        }
                    />
                    <Typography variant="caption" sx={{ margin: 'auto', position: 'absolute' }}>{finalPageNames[currentPage]}</Typography>
                </Box>
            }
            <Paper elevation={3}
                sx={{
                    ...boxWidth,
                    mt: 4,
                    mb: 8,
                    p: 1,
                    borderRadius: 2,
                    bgcolor: Colors.MMYellow,
                    // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",

                }}>


                <Box>
                    <Routes>
                        <Route path='/' element={<SurveyStart setCurrentPage={setCurrentPage} />}
                        />
                        <Route path='/1' element={<SurveyForm />} />

                        {
                            orderedCategories
                                .map((group, index) => (
                                    <Route
                                        key={index}
                                        path={`/${index + 2}`}
                                        element={
                                            <QuestionGroups
                                                groupName={group.name}
                                                questionGroups={questionGroups.filter(x => x.parentId === group.id)}
                                                questions={questions}
                                                categoryId={group.id}
                                            />
                                        }
                                    />
                                ))
                        }

                        <Route path={`/${lastPage}`} element={<SurveyEnd />} />
                        <Route path={`/${lastPage}`} element={<div>asd</div>} />
                    </Routes>

                    <Outlet />

                    {/* {currentPage > 0 &&
                    <Stack direction="row" spacing={2} mt={2} justifyContent="space-between">
                        <Button
                            onClick={prevPage}
                            disabled={currentPage === 0}
                            sx={{
                                backgroundColor: 'black',
                                color: Colors.MMYellow1,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'lightgrey',
                                    color: 'grey',
                                },
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            onClick={nextPage}
                            disabled={currentPage == lastPage}
                            sx={{
                                backgroundColor: 'black',
                                color: Colors.MMYellow1,
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                },
                                '&.Mui-disabled': {
                                    backgroundColor: 'lightgrey',
                                    color: 'grey',
                                },
                            }}
                        >
                            Next
                        </Button>
                    </Stack>
                } */}
                    {(currentPage > 0 && !isSmallScreen && !open) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <MobileStepper
                                variant="progress"
                                steps={lastPage + 1}
                                position="static"
                                activeStep={currentPage}
                                sx={{
                                    maxWidth: 400, flexGrow: 1,
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black',
                                    },
                                    '& .MuiLinearProgress-root': {
                                        backgroundColor: 'lightgrey',
                                    },
                                }}
                                nextButton={
                                    <Button size="small" onClick={nextPage} disabled={currentPage === lastPage}
                                        sx={{ color: 'black' }}>
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={prevPage} disabled={currentPage === 0}
                                        sx={{ color: 'black' }}>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </Button>
                                }
                            />
                            <Typography variant="caption" sx={{ margin: 'auto', position: 'absolute' }}>{finalPageNames[currentPage]}</Typography>
                        </Box>
                    }
                </Box>
            </Paper>
        </>

    );
}