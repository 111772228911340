import { Box, Typography, Button, TextField, Grid, Paper, Snackbar, Alert } from "@mui/material";
import { IAnswer, IAnswerSelectionType, ISubmitAnswersModel } from "../survey-questions/answers/answer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IQuestion } from "../survey-questions/survey-questions";
import { useEffect, useState } from "react"; // Import useState for managing the snackbar state
import { Colors } from "../../../constants/constants";
import surveySlice from "../../../store/survey/survey-slice";
import { Link } from "react-router-dom";
import { SubmitAnswersInDatabase } from "../../../store/survey/survey-actions";
import { IHealthAssesment } from "../survey-start/survey-start";

export interface ISurveyEndProps { }

export const SurveyEnd: React.FunctionComponent<ISurveyEndProps> = (props) => {
    // const [open, setOpen] = useState(false); // State to control Snackbar visibility
    const open = useSelector((state: RootState) => state.survey.SnackbarStartPage)
    const dispatch: any = useDispatch();
    const [zakljucakContent, setZakljucak] = useState<string>('')

    const zakljucak: IQuestion | undefined = useSelector((state: RootState) => state.survey.Questions)
        .find(x => x.id == 96);
    
    const healthAssesment: IHealthAssesment = useSelector((state: RootState) => state.survey.HealthAssesment)

    const handleClick = () => {
        dispatch(surveySlice.actions.setSnackbarStartPage(true))
    };

    //hardcode zakljucak id
   useEffect(() => {
    const answer: IAnswerSelectionType = {
        answerId: 610,
        isSelected: true,
        text: zakljucakContent
    }
    const body: ISubmitAnswersModel = {
        questionId: zakljucak?.id ?? 0,  
        healthAssesmentId: healthAssesment?.id ?? 0,
        answers: [answer]
        // additionalComment: zakljucakContent
    }
    const timer = setTimeout(() => {
        dispatch(SubmitAnswersInDatabase(fetch, body));
    }, 2000);

    return () => {
        clearTimeout(timer);
    };
   },[zakljucak])

    return (
        <Box
            sx={{
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'stretch',
                alignItems: 'center',
                textAlign: 'center',
                px: 0,
                height: '80vh',
            }}
        >
            <Grid
                container
                spacing={0}
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "stretch",
                    flexGrow: 1,
                    height: '100%',
                }}
            >
                <Grid item sx={{ width: '100%', mb: 0 }}>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography
                            variant="h4"
                            component="h2"
                            sx={{ pb: 2, mb: 0, borderColor: 'divider' }}
                        >
                            Hvala vam što ste popunili ovu anketu!
                        </Typography>
                    </Box>
                </Grid>

                {/* Middle Grid Item that expands */}
                <Grid
                    item
                    sx={{
        
                        width: '100%',
                        height: '70%',
                        p: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}
                >
                    <Box sx={{
                     
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Typography
                            variant="body1"
                            component="p"
                            sx={{ pb: 4, maxWidth: '600px', fontWeight: 'bold' }}
                        >
                            {zakljucak?.text}
                        </Typography>

                        <Box sx={{ backgroundColor: 'white', maxWidth: '600px', width: '100%', borderRadius: '5px' }}>
                            <TextField
                            onChange={(e) => setZakljucak(e.target.value)}
                                label="Vaš komentar"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                sx={{
                                    width: '100%',
                                    mb: 0
                                }}
                                InputProps={{
                                    style: { border: 'none' },
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item>
                <Link to={"/"}>
                <Button
                        onClick={handleClick}
                        variant="contained"
                      
                        sx={{
                            backgroundColor: '#1976d2', 
                            color: '#fff',
                            padding: '10px 20px',
                            fontSize: '16px',
                            borderRadius: '8px',
                            mb:3,
                            ':hover': {
                                backgroundColor: '#115293', 
                            },
                        }}
                    >
                       SUBMIT (POŠALJI)
                    </Button>
                    </Link>
                  
                </Grid>
            </Grid>
        </Box>
    );
};
