import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IQuestionGroup } from "../../components/survey/survey-question-groups";
import { IAnswer } from "../../components/survey/survey-questions/answers/answer";
import { IHealthAssesment } from "../../components/survey/survey-start/survey-start";
import { IQuestion } from "../../components/survey/survey-questions/survey-questions";
import { AccessTime } from "@mui/icons-material";
// import { IUserData } from "../../components/survey/survey-form/survey-form";
// import { fetchQuestionGroups } from "./survey-actions";

const initialState: ISurveyState = {
    QuestionGroups: [],
    Questions: [],
    Answers: [],
    HealthAssesment: {
        id: null,
        userId: null,
        startedOn: null,
        completedOn: null
    },
    SnackbarStartPage: false
    // UserData: undefined
}

type ISurveyState = {
    QuestionGroups: IQuestionGroup[],
    Questions: IQuestion[],
    Answers: IAnswer[],
    HealthAssesment: IHealthAssesment,
    SnackbarStartPage: boolean
    // UserData: IUserData | undefined
}

const surveySlice = createSlice({
    name: "survey",
    initialState,
    reducers: {
        setSnackbarStartPage: (state, action: PayloadAction<boolean>) => {
            state.SnackbarStartPage = action.payload;
        },
        setQuestionGroups: (state, action: PayloadAction<IQuestionGroup[]>) => {
            state.QuestionGroups = action.payload;
        },
        setOpenQuestionGroup:(state, action: PayloadAction<IQuestionGroup>)=>{
            const index = state.QuestionGroups.findIndex(x => x.id === action.payload.id)
            if (index !== -1) {
                state.QuestionGroups = state.QuestionGroups.map(x => {return {...x, isOpen: false }})
                state.QuestionGroups[index] = {...action.payload, isOpen: !action.payload.isOpen}
            }
        },
        setQuestions: (state, action: PayloadAction<IQuestion[]>) => {
            state.Questions = action.payload;
        },
        setQuestion: (state, action: PayloadAction<{ questionId: number, isSavedInDB: boolean }>) => {
            const updatedQuestion = action.payload;
            const index = state.Questions.findIndex(q => q.id === updatedQuestion.questionId);

            if (index !== -1) {
                state.Questions[index] = { ...state.Questions[index], isSavedInDB: updatedQuestion.isSavedInDB };

                // const possibleAnswers = state.Answers.filter(a => a.questionId === updatedQuestion.questionId)
                // const question = state.Questions[index]
                // if (possibleAnswers.filter(a => a.isSelected).length === question.maxSelect) {
                //     state.Questions[index] = { ...question, isAnswered: true }
                // } else {
                //     state.Questions[index] = { ...question, isAnswered: false }
                // }
                // console.log('question', state.Questions[index])
            }
            
        },
        setQuestionError: (state, action: PayloadAction<{ questionId: number, errorMsg: string | undefined }>) => {
            const questionErr = action.payload
            const index = state.Questions.findIndex(q => q.id === questionErr.questionId);

            if (index !== -1) {
                state.Questions[index] = { ...state.Questions[index], error: questionErr.errorMsg };
            }
        },
        setAnswers: (state, action: PayloadAction<IAnswer[]>) => {
            state.Answers = action.payload
        },
        setAnswerSlider: (state, action: PayloadAction<IAnswer>) => {
            const updatedAnswer = action.payload;

            const indexA = state.Answers.findIndex(x => x.id == updatedAnswer.id)
            const indexQ = state.Questions.findIndex(x => x.id === updatedAnswer.questionId)

            state.Answers = state.Answers.map(answer => {
                if (answer.questionId === updatedAnswer.questionId) {
                    return {
                        ...answer,
                        isSelected: false,
                        isAnswered: false
                    };
                }
                return answer;
            });

            // console.log( state.Answers.filter(x => x.questionId == indexQ))
            state.Answers[indexA] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected, isAnswered: true }

        },
        setAnswer: (state, action: PayloadAction<IAnswer>) => {
            // const updatedAnswer = action.payload;
            // console.log('updatedAnswer',updatedAnswer)

            // const indexA = state.Answers.findIndex(x => x.id == updatedAnswer.id)
            // const indexQ = state.Questions.findIndex(x => x.id === updatedAnswer.questionId)

            // const numberOfSelected = state.Answers.filter(x => x.isSelected && x.questionId === updatedAnswer.questionId).length

            // const maxSelect = state.Questions[indexQ].maxSelect

            // //
            // const findQuenstion = state.Questions[indexQ]
            // const numberOfAnswersPerQuestion = state.Answers.filter(x => x.questionId == findQuenstion.id).length
            // //
            // if(numberOfAnswersPerQuestion == 2)
            // {
            //     const isAlreadySelected = state.Answers.findIndex(x => x.id == updatedAnswer.id && x.isSelected && updatedAnswer.isSelected)
            //     state.Answers.forEach((element, index) => {
            //         if(element.questionId == findQuenstion.id){
            //             // state.Answers[indexA] = { ...updatedAnswer, isSelected: false, isAnswered: false }
            //             state.Answers[index] = {...element, isSelected: false, isAnswered: false}
            //             // console.log(element.id)
            //         }
            //     })
            //     // console.log(isAlreadySelected)
            //     if(isAlreadySelected == -1)
            //         state.Answers[indexA] = { ...updatedAnswer, isSelected: true, isAnswered: true }
            // }
            // else
            // {
            //     // console.log('is allowed',maxSelect > numberOfSelected)
            //     if (indexA !== -1 && maxSelect > numberOfSelected) {
            //         state.Answers[indexA] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected, isAnswered: true }
            //     } else if (updatedAnswer.isSelected) {
            //         state.Answers[indexA] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected, isAnswered: false }
            //     }
            // }


            const updatedAnswer = action.payload;
            const answerIndex = state.Answers.findIndex(a => a.id === updatedAnswer.id)
            // console.log('updatedAnswer', updatedAnswer)
            const questionIndex = state.Questions.findIndex(q => q.id === updatedAnswer.questionId)
            const question = state.Questions[questionIndex]
       
            const allAnswers = state.Answers.filter(a => a.questionId === question.id)

            if (question.maxSelect === 1) {
                //set all on isSelected False
                state.Answers = state.Answers.map((a) => {
                    return a.questionId === question.id ? { ...a, isSelected: false} : a
                })
                //set answer to isSelected true
                state.Answers[answerIndex] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected }

            } else if (allAnswers.filter(a => a.isSelected).length < question.maxSelect) {
                state.Answers[answerIndex] = { ...updatedAnswer, isSelected: !updatedAnswer.isSelected}
            } else {
                state.Answers[answerIndex] = { ...updatedAnswer, isSelected: false}
            }



        },
        setAnswerSimple: (state, action: PayloadAction<IAnswer>) => {
            state.Answers[state.Answers.findIndex(x => x.id === action.payload.id)] = action.payload
            // console.log("answer", state.Answers[state.Answers.findIndex(x => x.id === action.payload.id)])
        },
        setHealthAssesment: (state, action: PayloadAction<IHealthAssesment>) => {
            state.HealthAssesment = action.payload
        }
        // setUserData: (state, action: PayloadAction<IUserData>) => {
        //     state.UserData = action.payload
        // }
    }
});

export const surveyActions = surveySlice.actions;
export default surveySlice;