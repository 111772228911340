import React, { useEffect } from 'react';
import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SurveyPage } from './pages/survey-page/survey-page';
import { LayoutPage } from './pages/layout-page';
import { useDispatch, useSelector } from 'react-redux';
import { setBrowserSize } from './store/general/general-slice';
import { CookieLoginUser } from './store/login-modal-form/login-modal-form-actions';
import { RootState } from './store/store';
import { MedicalCheckupPage } from './pages/medical-checkup/medical-checkup-page';

export interface AppProps {}

export const App: React.FunctionComponent<AppProps> = () => {
  const dispatch: any = useDispatch();
  const IsAuthenticated: boolean = useSelector((state: RootState) => state.loginModal.IsAuthenticated);

  useEffect(() => {
    dispatch(CookieLoginUser(fetch));
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      dispatch(setBrowserSize({ browserWidth: window.innerWidth, browserHeight: window.innerHeight }));
    };
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  return (
    <div className='fixScrollBarProblem'>
      <Routes>
        {/* Authenticated routes */}
        <Route element={<LayoutPage />}>
          <Route path='*' element={<SurveyPage />} />
          <Route path='/VerifyMailPage' element={<div>Thank you for registering, you can now log in.</div>} />
          <Route path='/MedicalCheckup' element={<MedicalCheckupPage checkupType={"KV"}/>}/>
        </Route>

        {/* Catch-all route */}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </div>
  );
};
