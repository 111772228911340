import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Tabs, Tab, Box } from '@mui/material';
import { useState } from 'react';
import "./barchart.css"
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { IAnswer } from '../../survey/survey-questions/answers/answer';
import { IQuestionGroup } from '../../survey/survey-question-groups';
import { IQuestion } from '../../survey/survey-questions/survey-questions';


type Orientation = 'vertical' | 'horizontal';

const yesResponses = {
  data: [0, 0, 0, 4, 5, 6, 5, 8, 9, 7],
  label: 'Positive',
  color: 'lightcoral',
};

const noResponses = {
  data: [24, 2, 11, 12, 12, 13, 18, 12, 11, 13],
  label: 'Negative',
  color: 'lightgreen',
};

interface IResponse {
  data: number[],
  label: string,
  color: string
}

// const categories: string[] = [
//   'Alergije',
//   'Lekovi',
//   'Anestezija',
//   'Simptomi bolesti',
//   'Prevencija i screening',
//   'KARDIOVASKULARNI SISTEM',
//   'RESPIRATORNI SISTEM',
//   'ENDOKRINI/GASTRO/NEFROLOSKI SA UROLOGIJOM',
//   'OSTALA MEDICINSKA ISTORIJA',
//   'NEUROLOŠKA OBOLJENJA',
// ];


export default function StackedBarChart() {
  const [tabValue, setTabValue] = useState<number>(0); // To switch between tabs
  const [orientation, setOrientation] = useState<Orientation>('vertical'); // To switch layout

  const questionGroups: IQuestionGroup[] = useSelector((state: RootState) => state.survey.QuestionGroups).filter(x => x.parentId === 3 && x.name !== undefined)
  const questions: IQuestion[] = useSelector((state: RootState) => state.survey.Questions).filter(question => questionGroups.map(qg => qg.id).includes(question.questionGroupId))
  const answers: IAnswer[] = useSelector((state: RootState) => state.survey.Answers).filter(answer => questions.map(q => q.id).includes(answer.questionId))

  const categories: string[] = questionGroups !== undefined
    ? questionGroups
      .map(x => x.name as string)
    : [""];

  


  const answerCountsPerCategory: {category: string, totalAnswers: number}[] = questionGroups.map(qg => {

    // Get question IDs for the current question group
    const questionIds = questions.filter(q => q.questionGroupId === qg.id).map(q => q.id);
  
    // Count total answers for those questions
    const totalAnswers = answers.filter(a => questionIds.includes(a.questionId) && a.isSelected && a.text.toLowerCase() !== 'ne' && a.text !== '0').length;
  
    return {
      category: qg.name as string,  // Ensure category is a string
      totalAnswers: totalAnswers
    };
  });

  const answerCountsPerCategoryNO: {category: string, totalAnswers: number}[] = questionGroups.map(qg => {

    // Get question IDs for the current question group
    const questionIds = questions.filter(q => q.questionGroupId === qg.id).map(q => q.id);
  
    // Count total answers for those questions
    const totalAnswers = answers.filter(a => questionIds.includes(a.questionId) && a.isSelected && (a.text.toLowerCase() == 'ne' || a.text == '0')).length;
  
    return {
      category: qg.name as string,  // Ensure category is a string
      totalAnswers: totalAnswers
    };
  });

  const yesResponses1 = {
    data: answerCountsPerCategory.map(x => x.totalAnswers),
    label: 'Negative',
    color: 'lightcoral',
  };

  const noResponses1 = {
    data: answerCountsPerCategoryNO.map(x => x.totalAnswers),
    label: 'Positive',
    color: 'lightgreen',
  };
  // console.log(answerCountsPerCategory.map(x => x.category));



  // console.log('yesResponses1', yesResponses1.data)
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setOrientation(newValue === 0 ? 'vertical' : 'horizontal'); // Switch layout based on selected tab
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Tabs value={tabValue} onChange={handleTabChange} centered>
        <Tab label="Vertical" />
        <Tab label="Horizontal" />
      </Tabs>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
        {/* Bar Chart Container */}
        <Box sx={{ width: 600 }}>
          <BarChart
            width={600}
            height={300}
            layout={orientation} // Use layout property to toggle between vertical and horizontal
            xAxis={[
              {
                data: orientation === 'vertical' ? answerCountsPerCategory.map(x => x.category) : undefined,
                scaleType: orientation === 'vertical' ? 'band' : 'linear', // Categorical for vertical, continuous for horizontal
              },
            ]}
            yAxis={[
              {
                data: orientation === 'horizontal' ? answerCountsPerCategory.map(x => x.category) : undefined,
                scaleType: orientation === 'horizontal' ? 'band' : 'linear', // Categorical for horizontal, continuous for vertical
              },
            ]}
            series={[
              { ...yesResponses1, stack: 'total' },
              { ...noResponses1, stack: 'total' }
            ]}
            margin={{
              top: 50,
              right: 80, // Add space to the right for the custom legend
              bottom: 20,
              left: orientation === 'horizontal' ? 330 : 50, // Increase left margin when horizontal
            }}
            slotProps={{
              legend: { position: { vertical: 'top', horizontal: 'right' } } // Hide the default legend
            }}
          />
        </Box>

      </Box>
    </Box>
  );
}
